import React from 'react';
import '../styles/about.css';
import FadeInSection from './FadeInSection';

class About extends React.Component {
	constructor() {
		super();
		this.state = {
			expanded: true,
			activeKey: '1',
		};
		this.handleSelect = this.handleSelect.bind(this);
	}
	handleSelect(eventKey) {
		this.setState({
			activeKey: eventKey,
		});
	}
	render() {
		const one = (
			<p>
				<a href="">I am currently a 4th year undergrad. Graduation in May 2025.🧃
					Majored in CS and Business.</a>
				
				<br></br><br></br>I am particularly interested in pursuing opportunities in the healthcare industry 🏥 and am seeking roles in the following positions; however, ya gal is OPEN to exploring opportunities beyond that.
				<br></br><br></br><li>Software Engineering/Data Science</li> 
				<li>Product Management</li>
				<li>R&D Operations</li>
				<li> AI automation</li>
				<br></br>I have a background in both engineering and project management that's why I am interested in these roles.
			</p>
		);
		const two = (
			<div>
				<p >
				Although I am a girl of diverse interests, and my downtime is often dedicated
				playing badminton.🏸 I wind down by watching tv shows and reading novels.
				<br></br><br></br>Lately, I've been into creating music!!!🎼🎶As a hopeless romantic, I try to write songs 🎸 🎵 as well lol.
				
			</p>
			</div>

		);
		
		
		const desc_items = [one, two];

		const tech_stack = [
			'Java',
			'C++',
			'SQL/ R',
			'Python',
			'MS Office',
			'Mongodb',
			'Reactjs',
			'Expressjs',
			'Blender' ,
			'JS, HTML, CSS',
		];
		const tech_items = tech_stack.map((stack) => <li>{stack}</li>);
		return (
			<div id="about">
			<FadeInSection>
				<div className="section-header ">
				<span className="section-title"><b>/ about</b></span>
				</div>
				<div className="about-content">
				<div className="about-description">
					{[one]}
					{"Here are some technologies I have worked with:"}
					<ul className="tech-stack">
					{tech_stack.map(function (tech_item, i) {
						return (
						<FadeInSection delay={`${i + 1}00ms`}>
							<li>{tech_item}</li>
						</FadeInSection>
						);
					})}
					</ul>
					{[two]}
				</div>
				
				<div className="about-image">
					<img src="../mat/mee.jpg" alt="" />
				</div>
				

				</div>
			</FadeInSection>
			</div>
		);
	}
}

export default About;
