import styles from "../styles/software.css";
import Mylinks from "./Mylinks";
import Glide from '@glidejs/glide';
import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';
import React, { useEffect, useRef } from 'react';


export default function Software() {
  const glideRef = useRef(null);
  const progressRef = useRef(null);

  useEffect(() => {
    if (glideRef.current) {
      const glide = new Glide(glideRef.current);
      glide.mount();

      glide.on(["run.after"], () => {
        updateProgressBar(glide);
      });

      // Initial update of the progress bar
      updateProgressBar(glide);
    }
  }, []);

  const updateProgressBar = (glide) => {
    if (glide.slides && glide.slides.length > 0) {
      const slidesCount = glide.slides.length;
      const currentSlideIndex = glide.index;
  
      const progressBarWidth = 100 / slidesCount;
      const progressWidth = progressBarWidth * (currentSlideIndex + 1);
  
      progressRef.current.style.width = `${progressWidth}%`;
    }
  };

  const spotlightProjects = {
    "GroupMatch":{
      title: 'GroupMatch - Dating App',
      desc: 'Focuses on group outings and shared experiences, making dating less intimidating and more enjoyable.',
      techStack: 'TypeScript, Express.js, React.Js, Npm, Data Analytics, Marketing, Lean hypothesis',
      link: 'https://github.com/jojo142/GroupMatch',
      open: 'https://groupmatch.netlify.app/',
      image:  "/assets/groupmatch.png"
    },
    "RepairNet":{
      title: 'RepairNet',
      desc: 'Streamline real time facilities tracker dashboard. Submit maintenance requests fast and track updates in real-time Single Sign on. Very Convenient',
      techStack: 'Lean Hypothesis, Express.js, React.Js, Npm, Data Analytics, Social Media Marketing, Canva',
      link: 'https://repairnet.netlify.app/',
      open: 'https://docs.google.com/presentation/d/1p7RaAJvlhozaiZNgnl1EAkxvTi9vuVQrDb1tgnXi__M/edit?usp=sharing',
      image:  "/assets/repairnet.png"
    },
    "3D Portfolio": {
      title: "3D Portfolio",
      desc:
        "Three.js web app that showcases my blender isometric room. I added it as a background to my portfolio.",
      techStack: "Blender, Three.js, React.js, JS, HTML, CSS",
      link: "https://github.com/jojo142",
      open: "https://samiyatech.netlify.app/",
      image: "/assets/port.png"
    },
    "Bunny Dash":{
      title: "Bunny Dash Game",
      desc:
        "Game created with Three.js. The game is about a bunny that is trying to escape from a fox. The bunny has to collect as many carrots as possible to win. Jumps to collect. There's an audio to make the game more fun.",
      techStack: " Blender, Three.js, React.js, JS, HTML, SCSS",
      link: "https://github.com/jojo142",
      open: "https://bunnydash.netlify.app/",
      image: "/assets/bunnyDash.png",

    },
    "TradeSense.io": {
      title: "TradeSense.io",
      desc:
        "A web application that allows users to track their stock portfolio and simulate trades. It also provides users with a dashboard that displays their portfolio's performance.",
      techStack: "Ruby, C++, Dart, JAVASCRIPT, HTML, CSS",
      link: "https://github.com/jojo142/TradeSense.io",
      open: "",
      image: "/ux/trade.png"
    },
    "Agendussy": {
      title: "Agendussy Chrome Extension ",
      desc:
        " A productivity chrome extension that lets our generation to stay on task and get them done. Allows users add tasks, clear them and set a timer. Uses a 25 min pomodoro timer to help users stay productive. Tasks will still stay even if you close tabs unless you erase them. ",
      techStack: "JAVASCRIPT, HTML, CSS",
      link: "https://github.com/jojo142/Agendussy",
      open: "https://chromewebstore.google.com/detail/agendussy/fjkeibaligkgcgdjocidpobcdkboibcd",
      image: "/assets/agendussy.png"
    },
    "Duck Lord Loves All": {
      title: "Duck Lord Loves All",
      desc:
        "NonProfit Clothing Brand. Donates 100% of profits to charity that fights for animal rights and cease the cruelty. I was in charge of Marketing and Web Development.",
      techStack: "REACT.JS, MONGODB, NODE.JS, Social Media Marketing",
      link: "http://ducklordlovesall.surge.sh/",
      image: "/assets/dl.jpeg"
    },
    "Wave reacting to Audio":{
      title: "Wave reacting to Audio",
      desc:
        "Generative art project that creates a wave according to audio wavelengths. ",
      techStack: "Processing, P5js, SCSS, HTML",
      link: "https://codepen.io/jojo142/pen/zYyrOpj",
      image: "/assets/wave.png",
    },

    "Finance Tracker": {
      title: "Finance Tracker",
      desc:
        "A productivity web application that allows users to track their transaction. It also provides users with a dashboard that displays their transaction history and categorizes their spending.",
      techStack: "OpenAI API, Express.js, NODE.JS (SOCKET.IO), REACT.JS, MONGODB",
      link: "https://github.com/jaimie17/cs103aSpr23TeamProjects/tree/main/ca02",
      open: "https://finance-tracker-103.herokuapp.com/",
      image: "/assets/productivityApp.png"
    },
    "GonkCube": {
      title: "GonkCube",
      desc:
        "THREEJS Project. A spinning cube in a 3d world simulation. Hover over the cube for a surprise. ",
      techStack: "Three.js, React.js",
      link: "https://github.com/jojo142/GONK-CUBE",
      open: "https://gonkcube.netlify.app/",
      image: "/assets/gonk.png"
    },
    "FruitSlice":{
      title: "FruitSlice",
      desc:
        "Generative art project that uses Java GUI to create a fruit slice. All the mesuarements are 1:3 ratio. ",
      techStack: "Java GUI, JSwing",
      link: "https://github.com/jojo142/Generative_Art",
      image: "/assets/fs.png",
    },

    "TextTrove": {
      title: "TextTrove",
      desc:
        "A web application that allows users to generate text based on a given prompt. Can create poems, translate Spanish, generate synonyms, and summarize input texts.",
      techStack: "OpenAI API, Mongodb, React.js, Node.js, Express.js, Material-UI",
      link: "https://github.com/jaimie17/cs103aSpr23TeamProjects/tree/main?tab=readme-ov-file#texttrove",
      open: "https://texttrove.herokuapp.com/",
      image: "/assets/TextTrove.png"
    }
    
  };

  return (
    <div id="software" className={styles.projects}>
      <div className="section-header">
        <span className="section-title">/ software-creations</span>
      </div>

      <div className="spotlight-container" ref={glideRef}>
        <div className="uxdesign-glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {Object.keys(spotlightProjects).map((key, index) => (
              <li className="glide__slide" key={index}>
                <div className="spotlight-card">
                  <div className="spotlight-header">
                    <img
                      className="spotlight-image"
                      src={spotlightProjects[key]["image"]}
                      alt={key}
                    />
                    <div className="spotlight-title">
                    {spotlightProjects[key]["title"]}
                  </div>
                  <div className="spotlight-desc">
                    {spotlightProjects[key]["desc"]}
                  </div>
                  <div className="spotlight-tech">
                    {spotlightProjects[key]["techStack"]}
                    
                  </div>
                  <Mylinks styles={{align: "center"}}
                      githubLink={spotlightProjects[key]["link"]}
                      openLink={spotlightProjects[key]["open"]}
                    />
                  </div>
                  
                </div>
                
              </li>
            ))}
            
          </ul>
        </div>
      </div>
      
    </div>

  );
}  